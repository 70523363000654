

<template>
  <div>
    <Pane />

    <div class="container">
      <a-row>
        <a-col :span="20">
          <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.designCode" placeholder="设计编号" style="width: 150px"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目名称">
              <ContractSelector @change="onSelectProject" style="width: 150px">
                <a-button
                  block
                  style="text-align: left"
                  class="ellipsis"
                >{{ selectedProject }}</a-button>
              </ContractSelector>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.buyerUnitName" placeholder="购方名称" style="width: 150px"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.invoiceCode" placeholder="发票号码" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.status" placeholder="发票状态" style="width: 150px">
                <a-select-option
                  v-for="item in invoiceStatusList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker v-model="form.invoiceDate" style="width: 240px" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="4">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <a-button
                v-if="$getPermission($route.path + '/apply')"
                @click="$router.push($route.path + '/apply')"
              >开票退票换票申请</a-button>

              <ExcelUploader
                :loading="uploadLoading"
                @setLoading="setUploadLoading"
                v-if="$getPermission($route.path + '/add')"
              >
                <a-button :loading="uploadLoading">导入发票</a-button>
              </ExcelUploader>

              <a-button @click="download" type="primary">导出发票</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :scroll="scroll"
      >
        <a-table-column title="设计编号" data-index="designCode" ellipsis="true" width="120px">
          <template slot-scope="designCode">
            <a-tooltip>
              <template slot="title">{{ designCode }}</template>
              {{ designCode }}
            </a-tooltip>
          </template>
        </a-table-column>

        <a-table-column title="项目名称" data-index="contractName" ellipsis="true">
          <template slot-scope="contractName">
            <a-tooltip>
              <template slot="title">{{ contractName }}</template>
              {{ contractName }}
            </a-tooltip>
          </template>
        </a-table-column>
        <a-table-column title="开票日期" data-index="invoiceDate" width="100px" align="center"></a-table-column>

        <a-table-column title="发票号码" data-index="invoiceCode" width="100px" align="center"></a-table-column>
        <a-table-column title="发票类型" data-index="type" align="center" width="100px">
          <template slot-scope="type">
            <a-tag color="orange" v-if="type === 'special'">专票</a-tag>
            <a-tag color="blue" v-if="type === 'normal'">普票</a-tag>
          </template>
        </a-table-column>

        <a-table-column key="buyerUnitName" title="购方名称" data-index="buyerUnitName" ellipsis="true">
          <template slot-scope="buyerUnitName">
            <a-tooltip>
              <template slot="title">{{ buyerUnitName }}</template>
              {{ buyerUnitName }}
            </a-tooltip>
          </template>
        </a-table-column>

        <a-table-column title="税点" data-index="taxRate" align="right" width="50px">
          <template slot-scope="taxRate">{{ taxRate }}%</template>
        </a-table-column>

        <a-table-column
          title="合计金额(元)"
          data-index="totalPriceLowercase"
          align="right"
          width="100px"
        >
          <template slot-scope="totalPriceLowercase">
            <span :style="moneyColor(totalPriceLowercase)">{{ moneyFilter(totalPriceLowercase) }}</span>
          </template>
        </a-table-column>

        <a-table-column title="清收人" ellipsis="true">
          <template slot-scope="text">
            {{ text.liquidatorDeptName }}
            <span
              v-if="text.liquidatorDeptName && text.liquidatorName"
            >-</span>
            {{ text.liquidatorName }}
          </template>
        </a-table-column>

        <a-table-column title="经办人" ellipsis="true">
          <template slot-scope="text">
            {{ text.operatorDeptName }}
            <span v-if="text.operatorDeptName && text.operatorName">-</span>
            {{ text.operatorName }}
          </template>
        </a-table-column>

        <a-table-column title="发票状态" data-index="status" align="center" width="100px">
          <template slot-scope="status">
            <div>
              <DataDictFinder dictType="invoiceStatus" :dictValue="status" iconType="badge" />
            </div>
          </template>
        </a-table-column>

        <a-table-column title="关联发票号码" width="100px" align="center">
          <template slot-scope="text">
            <span
              style="color: #1890ff; cursor: pointer"
              @click="queryCode(text)"
            >{{ text.relatedInvoiceCode }}</span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
              >编辑</a>
              <a href="#" @click.prevent="$router.push($route.path + '/detail?id=' + text.id)">详情</a>

              <a-dropdown
                v-if="$getPermission($route.path + '/delete') || $getPermission($route.path + '/redoff') || $getPermission($route.path + '/discard')"
              >
                <span style="color: #1890ff; cursor: pointer">更多</span>
                <a-menu slot="overlay">
                  <a-menu-item
                    v-if="$getPermission($route.path + '/delete')"
                    @click="deleteText(text)"
                  >删除</a-menu-item>
                  <a-menu-item
                    v-if="$getPermission($route.path + '/redoff')"
                    :disabled="text.status !== 'normal'"
                    @click="redoffset(text)"
                  >
                    <span style="color: #f00">红冲</span>
                  </a-menu-item>
                  <a-menu-item
                    v-if="$getPermission($route.path + '/discard')"
                    :disabled="text.status === 'discarded'"
                    @click="control('discarded', text)"
                  >作废</a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>

    <Redoffset :visible="visible" :activeText="activeText" @setVisible="setVisible" />
  </div>
</template>


<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";
import { fetchList, remove, exportFile } from "@/api/invoice";
import { mapGetters } from "vuex";
import Redoffset from "./redoffset.vue";
import ExcelUploader from "@/components/excel-uploader";
import { saveAs } from "file-saver";
import ContractSelector from "@/components/contract-selector";
import { fetchDetail } from "@/api/contract";
export default {
  name: "invoice",
  mixins: [screen, watermark],

  components: {
    Redoffset,
    ExcelUploader,
    ContractSelector
  },

  data() {
    return {
      form: {},
      selectedProject: '',
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false, // 红冲弹窗
      activeText: {}, // 正在红冲的发票

      uploadLoading: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    invoiceStatusList() {
      return this.findDataDict("invoiceStatus");
    }
  },

  activated() {
    const { query } = this.$route;
    const { code, invoiceCode } = query || {};
    if (code) {
      this.form = {
        ...this.form,
        designCode: code,
        invoiceCode
      };
    }
    if (invoiceCode) {
      this.form = {
        ...this.form,
        invoiceCode
      };
    }

    this.getList();
  },

  watch: {
    // 关闭红冲弹窗后刷新数据
    visible(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.getList();
      }
    }
  },

  methods: {
    // 监听选中合同
    onSelectProject(contract) {
      fetchDetail(contract.id)
        .then(res => {
          if (res) {
            this.selectedProject = res.name;
            this.form.contractId = res.id;
          }
        })
        .catch(() => {
          this.form.contractId = '';
        });
    },
    getList() {
      const { current, pageSize, form } = this;
      const { invoiceDate } = form;
      let invoiceDateStart, invoiceDateEnd;
      if (Array.isArray(invoiceDate) && invoiceDate.length === 2) {
        invoiceDateStart = invoiceDate[0].format("YYYY-MM-DD");
        invoiceDateEnd = invoiceDate[1].format("YYYY-MM-DD");
      }
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
        invoiceDate: undefined,
        invoiceDateStart,
        invoiceDateEnd
      })
        .then(res => {
          console.log("res", res);
          if (Array.isArray(res.list)) {
            this.list = res.list.map(item => {
              return {
                ...item,
                key: item.id
              };
            });
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.selectedProject = '';
      this.current = 1;
      this.getList();
    },

    // 将一个票，添加它的红冲票
    redoffset(text) {
      this.activeText = text;
      this.visible = true;
    },
    setVisible(visible) {
      this.visible = visible;
    },

    queryCode(text) {
      this.form = {
        ...this.form,
        invoiceCode: text.redoffsetInvoiceCode
      };
      this.query();
    },

    statusStyle(status) {
      if (status === "normal") {
        return {
          color: "green"
        };
      } else if (status === "redoffset") {
        return {
          color: "red"
        };
      } else if (status === "discarded") {
        return {
          color: "gray"
        };
      } else {
        return {};
      }
    },

    moneyFilter(money) {
      let startIndex = 0;
      if (parseFloat(money) < 0) {
        startIndex = 1;
      }

      let str = String(money)
        .substring(startIndex)
        .split("")
        .reverse()
        .join("");

      let val = str
        .split("")
        .map((item, index) => {
          if (index + 1 === str.length) {
            return item;
          }
          if ((index + 1) % 3 === 0) {
            return item + ",";
          } else {
            return item;
          }
        })
        .join("")
        .split(",")
        .map(item => {
          return item
            .split("")
            .reverse()
            .join("");
        })
        .reverse()
        .join(",");

      if (startIndex === 1) {
        return "-" + val;
      } else {
        return val;
      }
    },

    moneyColor(money) {
      if (parseFloat(money) < 0) {
        return {
          color: "#f00"
        };
      } else {
        return {
          color: "#1890ff"
        };
      }
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个发票吗？",
        onOk() {
          remove({
            ids: [text.id],
            status: "deleted"
          }).then(() => {
            that.getList();
          });
        }
      });
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },

    control(status, text) {
      const that = this;

      this.$confirm({
        title: `确认要${status === "redoffset" ? "红冲" : "作废"}这个发票吗？`,
        onOk() {
          remove({
            ids: [text.id],
            status
          }).then(() => {
            that.getList();
          });
        }
      });
    },

    sure(status) {
      if (status === "discarded") {
        return;
      }

      let flag1 = false;
      let flag2 = false;
      let flag3 = false;
      this.selectedRowKeys.forEach(item => {
        const obj = this.list.find(element => element.key === item);
        if (obj && obj.redoffsetInvoiceId) {
          flag2 = true;
        }
        if (obj && obj.status === "redoffset") {
          flag1 = true;
        }
        if (obj && obj.status === "discarded") {
          flag3 = true;
        }
      });

      if (flag1) {
        this.$message.error("发票已被红冲，请重新选择！");
        return;
      }
      if (flag2) {
        this.$message.error("该发票存在已红冲的发票，请重新选择！");
        return;
      }
      if (flag3) {
        this.$message.error("该发票已作废，请重新选择！");
        return;
      }

      remove({
        ids: this.selectedRowKeys,
        status: status
      }).then(() => {
        this.getList();
      });
    },

    setUploadLoading(payload) {
      this.uploadLoading = payload;
    },

    download() {
      const {
        buyerUnitName,
        contractId,
        designCode,
        invoiceCode,
        status,
        type,
        invoiceDate
      } = this.form;
      let invoiceDateStart, invoiceDateEnd;
      if (Array.isArray(invoiceDate) && invoiceDate.length === 2) {
        invoiceDateStart = invoiceDate[0].format("YYYY-MM-DD");
        invoiceDateEnd = invoiceDate[1].format("YYYY-MM-DD");
      }

      const params = {};
      if (buyerUnitName) {
        params.buyerUnitName = buyerUnitName;
      }
      if (contractId) {
        params.contractId = contractId;
      }
      if (designCode) {
        params.designCode = designCode;
      }
      if (invoiceCode) {
        params.invoiceCode = invoiceCode;
      }
      if (status) {
        params.status = status;
      }
      if (type) {
        params.type = type;
      }
      if (invoiceDateStart) {
        params.invoiceDateStart = invoiceDateStart;
      }
      if (invoiceDateEnd) {
        params.invoiceDateEnd = invoiceDateEnd;
      }

      exportFile({
        ...params
      })
        .then(blob => {
          saveAs(blob, "导出发票.xlsx");
        })
        .catch(err => {
          console.log("err", err);
          this.$message.error("导出出错！");
        });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 8px;
}

.download {
  float: right;
}

.button {
  padding: 6px 12px;
  img {
    width: 18px;
    margin-right: 4px;
  }
  &:hover {
    background-color: #f7f7f7;
    cursor: pointer;
    border-radius: 4px;
  }
}

.form-item {
  margin-bottom: 6px;
}
</style>