<template>
  <span>
    <input
      ref="excelUploader"
      id="excel-uploader"
      type="file"
      @change="onChange"
      :multiple="multiple"
    />

    <span @click="onClick">
      <slot />
    </span>
  </span>
</template>

<script>
import { upload } from "@/api/invoice";
export default {
  props: {
    uploadType: {
      type: String,
      default: "file",
    },
    imagePath: {
      type: String,
      default: "",
    },
    // 是否可以多选
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$refs.excelUploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.$emit("setLoading", true);
      const hide = this.$message.loading("上传中...", 0);

      upload(data)
        .then((res) => {
          console.log("upload res", res);
          if (res !== "success") {
            this.$error({
              title: "提示",
              content: res,
            });
          } else {
            this.$message.success("上传成功！");
          }
        })
        .finally(() => {
          this.$emit("setLoading", false);
          hide();
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#excel-uploader {
  display: none;
}
</style>