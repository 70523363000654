var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"添加红冲票","visible":_vm.visible,"footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"发票号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'invoiceCode',
          { rules: [{ required: true, message: '请输入！' }] },
        ]),expression:"[\n          'invoiceCode',\n          { rules: [{ required: true, message: '请输入！' }] },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"开票日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'invoiceDate',
          { rules: [{ required: true, message: '请选择！' }] },
        ]),expression:"[\n          'invoiceDate',\n          { rules: [{ required: true, message: '请选择！' }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1),_c('a-divider'),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("保存")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }