<template>
  <a-modal
    title="添加红冲票"
    :visible="visible"
    :footer="null"
    @cancel="cancel"
  >
    <a-form
      :form="form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 12 }"
      :colon="false"
      @submit="handleSubmit"
    >
      <a-form-item label="发票号码">
        <a-input
          v-decorator="[
            'invoiceCode',
            { rules: [{ required: true, message: '请输入！' }] },
          ]"
        />
      </a-form-item>

      <a-form-item label="开票日期">
        <a-date-picker
          placeholder=""
          v-decorator="[
            'invoiceDate',
            { rules: [{ required: true, message: '请选择！' }] },
          ]"
          style="width: 100%"
        />
      </a-form-item>

      <a-divider />
      <div class="right">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" html-type="submit" :loading="loading"
            >保存</a-button
          >
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>


<script>
import { redoffset } from "@/api/invoice";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeText: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
    };
  },

  methods: {
    cancel() {
      this.$emit("setVisible", false);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          const invoiceDate = values.invoiceDate.format("YYYY-MM-DD");

          this.loading = true;

          redoffset({
            ...values,
            invoiceDate,
            oriInvoiceId: this.activeText.id,
            oriInvoiceCode: this.activeText.invoiceCode,
          })
            .then(() => {
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>